.button,
.button:focus,
.button:active,
.button:disabled {
  background-color: #adadad !important;
  border-color: #bdbdbd #a8a8a8 #a8a8a8 #bdbdbd !important;
  color: black !important;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-weight: 400;
  height: 26px;
  min-width: 75px;
  font-size: 13px;
  margin-top: 10px;
  line-height: normal;
  overflow-x: visible;
  overflow-y: visible;
  padding: 0 10px;
  box-shadow: none !important;
}

.button:hover {
  border-color: #6F6F6F !important;
}

.button-primary,
.button-primary:hover,
.button-primary:focus,
.button-primary:active,
.button-primary:disabled {
  background-color: #111 !important;
  border-color: #1a1a1a #060606 #060606 #1a1a1a !important;
  color: white !important;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  font-weight: 400;
  height: 26px;
  min-width: 75px;
  font-size: 13px;
  margin-top: 10px;
  line-height: normal;
  overflow-x: visible;
  overflow-y: visible;
  padding: 0 10px;
  outline: none;
  box-shadow: none !important;
}
