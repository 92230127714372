@import "./Styles/alert.css";
@import "./Styles/button.css";
@import "./Styles/card.css";
@import "./Styles/form.css";
@import "./Styles/spinner.css";
@import "./Styles/table.css";
@import "./Styles/list.css";

body {
  margin: 0;
  background-color: #F1F8FA;
  font-family: Roboto, sans-serif;
}
