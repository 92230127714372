.card {
  background-color: white;
  border: 1px solid #ddd;
  border-top: white solid 3px;
  border-radius: 0;
}

.card-header {
  background-color: #317796;
  background-image: -webkit-linear-gradient(top, #5b93ab 0%, #317796 100%);
  color: white;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  padding: 3px 8px;
  margin: 0;
  border: none;
}

.card-header:first-child {
  border-radius: 0;
}
