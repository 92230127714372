.alert-image-container {
  width: 32px;
  margin: 10px;
  position: absolute;
}

.alert-image {
  height: 32px;
  width: 32px;
}

.alert-message-container {
  color: #434343;
  margin-top: 12px;
  margin-left: 52px;
  margin-right: 10px;
  overflow-x: auto;
  font-size: 9pt;
  display: block;
}

.alert-button-container {
  margin-top: 2px;
  margin-right: 15px;
  margin-bottom: 20px;
  text-align: right;
}
