.form-label {
  color: black;
  font-size: 12px;
  font-weight: 700;
  padding: 3px 10px 3px 0!important;
}

.form-control {
  color: black;
  background-color: white;
  border-color: #767676;
  border-width: 1px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 400;
  height: 14px;
  margin: 2px;
  padding: 9px 4px;
  width: 120px;
}

.form-control[readonly] {
  box-shadow: none !important;
  border-color: #767676;
  color: #212529;
}

.select {
  height: 20px;
  padding: 0 4px;
}

.select-indexation {
  width: 80px;
}

.value-input {
  width: 80px;
}

.display-inline {
  display: flex;
  align-items: baseline;
}

.indexation-span {
  display: none;
  padding: 5px 3px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
