table {
  color: #434343;
  font-size: 12px;
}

.table td {
  vertical-align: middle;
  height: 35px;
}

.table-sm th, .table-sm td {
  padding: 5px 3px;
}

.table-striped > tbody > tr:nth-child(2n+1) > td {
  background-color: #E9F4F8;
}
